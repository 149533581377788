.App {
  background-color: #f5e9f3;
  text-align: center;
}

.App-header {
  background-color: #f5e9f3;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.entire {
  /* background-color: #dce2f2; */
  /* background-color: #f5e9f3; */
  margin: 350;
  text-align: justify;
}

.heading {
  color: #5e0034;
}

.button {
  color:#dce2f2;
  margin: 3%;
  text-align: right;
}

.main {
  text-align: center;
  margin: 2% auto;
  padding: 7px 10px;
  border: 1px solid #f5e9f3;
  background-color: #f5e9f3;
  width: 25%; 
}

.entire2 {
  /* background-color: #dce2f2; */
  /* background-color: #f5e9f3; */
  color: #5e0034;
  margin: 250;
  text-align: justify;
}

.button2 {
  color:#dce2f2;
  margin: 3%;
  text-align: right;
}

.main2 {
  text-align: center;
  margin: 2% auto;
  padding: 7px 10px;
  width: 50%; 
}

.context {
  text-align: center;
  font-size: 22px;
  margin: 2% auto;
  width: 35%; 
}

.entire3 {
  /* background-color: #f5e9f3; */
  color: #5e0034;
  margin: 350;
  text-align: justify;
}


.button3 {
  color:#dce2f2;
  margin: 3%;
  text-align: right;
}

.main3 {
  text-align: left;
  margin: 2% auto;
  padding: 7px 10px;
  border: 1px solid #f5e9f3;
  background-color: #f5e9f3;
  color: #5e0034;
  width: 25%; 
}

.context2 {
  text-align: center;
  font-size: 28px;
  margin: 2% auto;
  width: 25%; 
}

#postTitle {
  height:100;
  width:400px;
}

#postUsername {
  height:100;
  width:375px;
}

#postContent {
  height:300px;
  width:400px;
}

.button4 {
  color:#dce2f2;
  margin: 3%;
  text-align: center;
}
